<template>
  <div class="diagnosa">
    <h1>This is an Diagnosa page</h1>
    <BottomNav />
  </div>
</template>
<script>
import BottomNav from "../components/BottomNav.vue";
export default {
  name: "Diagnosa",
  components: { BottomNav },
};
</script>